import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7a9ebf09"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "list"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");

  var _component_el_table = _resolveComponent("el-table");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
    data: $setup.order_list,
    stripe: ""
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        prop: "id",
        label: $setup.t('訂單編號')
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "course_title",
        label: $setup.t('課程名稱')
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "price",
        label: $setup.t('購買價格')
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "method",
        label: $setup.t('付款方式')
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        prop: "time",
        label: $setup.t('時間')
      }, null, 8, ["label"])];
    }),
    _: 1
  }, 8, ["data"])]);
}