import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0a8df09a"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "container"
};
var _hoisted_2 = {
  class: "branding"
};
var _hoisted_3 = {
  key: 0,
  src: "/logo/logo.png"
};
var _hoisted_4 = {
  key: 1,
  src: "/logo/small_logo.png"
};
var _hoisted_5 = {
  key: 0,
  class: "nav-links"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");

  var _component_UserMenu = _resolveComponent("UserMenu");

  var _component_menuIcon = _resolveComponent("menuIcon");

  var _component_ui = _resolveComponent("ui");

  var _component_LanguageModal = _resolveComponent("LanguageModal");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("header", null, [_createElementVNode("nav", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_router_link, {
    to: {
      name: 'Home'
    }
  }, {
    default: _withCtx(function () {
      return [!$setup.moblie ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : (_openBlock(), _createElementBlock("img", _hoisted_4))];
    }),
    _: 1
  })]), !$setup.moblie ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("ul", null, [_createVNode(_component_router_link, {
    class: "link",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
      return $setup.mobile_nav = false;
    }, ["prevent"])),
    to: {
      name: 'Home'
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.t("免費學習")), 1)];
    }),
    _: 1
  }), _createVNode(_component_router_link, {
    class: "link",
    to: {
      name: 'Courses'
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.t("付費課程")), 1)];
    }),
    _: 1
  }), $setup.user ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    class: "link",
    to: {
      name: 'Enrolled'
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.t("已購買")), 1)];
    }),
    _: 1
  })) : _createCommentVNode("", true), !$setup.user && $setup.store.state.language ? (_openBlock(), _createElementBlock("span", {
    key: 1,
    onClick: _cache[1] || (_cache[1] = _withModifiers(function ($event) {
      $setup.show_language_modal = true;
    }, ["prevent"])),
    class: "link"
  }, _toDisplayString($setup.t("語言")) + ":" + _toDisplayString($setup.store.state.language), 1)) : _createCommentVNode("", true), !$setup.user ? (_openBlock(), _createBlock(_component_router_link, {
    key: 2,
    onClick: _withModifiers($setup.logIn, ["prevent"]),
    class: "link",
    to: {
      name: 'Login'
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.t("登入")), 1)];
    }),
    _: 1
  }, 8, ["onClick"])) : _createCommentVNode("", true)]), $setup.user && !$setup.moblie ? (_openBlock(), _createBlock(_component_UserMenu, {
    key: 0
  })) : _createCommentVNode("", true)])) : (_openBlock(), _createBlock(_component_menuIcon, {
    key: 1,
    onClick: $setup.toggleMobileNav,
    class: "menu-icon"
  }, null, 8, ["onClick"])), _createVNode(_Transition, {
    name: "mobile-nav"
  }, {
    default: _withCtx(function () {
      return [_withDirectives(_createVNode(_component_ui, {
        class: "mobile-nav"
      }, {
        default: _withCtx(function () {
          return [$setup.user ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            onClick: _cache[2] || (_cache[2] = _withModifiers(function ($event) {
              return $setup.mobile_nav = false;
            }, ["prevent"])),
            class: "link",
            to: {
              name: 'UserProfile',
              params: {
                id: $setup.user.uid
              }
            }
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString($setup.t("個人檔案")), 1)];
            }),
            _: 1
          }, 8, ["to"])) : _createCommentVNode("", true), _createVNode(_component_router_link, {
            class: "link",
            onClick: _cache[3] || (_cache[3] = _withModifiers(function ($event) {
              return $setup.mobile_nav = false;
            }, ["prevent"])),
            to: {
              name: 'Home'
            }
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString($setup.t("免費學習")), 1)];
            }),
            _: 1
          }), _createVNode(_component_router_link, {
            class: "link",
            onClick: _cache[4] || (_cache[4] = _withModifiers(function ($event) {
              return $setup.mobile_nav = false;
            }, ["prevent"])),
            to: {
              name: 'Courses'
            }
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString($setup.t("付費課程")), 1)];
            }),
            _: 1
          }), $setup.user ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            onClick: _cache[5] || (_cache[5] = _withModifiers(function ($event) {
              return $setup.mobile_nav = false;
            }, ["prevent"])),
            class: "link",
            to: {
              name: 'Enrolled'
            }
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString($setup.t("已購買")), 1)];
            }),
            _: 1
          })) : _createCommentVNode("", true), $setup.user ? (_openBlock(), _createBlock(_component_router_link, {
            key: 2,
            onClick: _cache[6] || (_cache[6] = _withModifiers(function ($event) {
              return $setup.mobile_nav = false;
            }, ["prevent"])),
            class: "link",
            to: {
              name: 'PurchaseHistory'
            }
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString($setup.t("購買紀錄")), 1)];
            }),
            _: 1
          })) : _createCommentVNode("", true), $setup.store.state.user && $setup.store.state.user.admin ? (_openBlock(), _createBlock(_component_router_link, {
            key: 3,
            class: "link",
            onClick: _cache[7] || (_cache[7] = _withModifiers(function ($event) {
              return $setup.mobile_nav = false;
            }, ["prevent"])),
            to: {
              name: 'Admin'
            }
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString($setup.t("後台管理")), 1)];
            }),
            _: 1
          })) : _createCommentVNode("", true), $setup.store.state.language ? (_openBlock(), _createElementBlock("span", {
            key: 4,
            onClick: _cache[8] || (_cache[8] = _withModifiers(function ($event) {
              $setup.show_language_modal = true;
            }, ["prevent"])),
            class: "link"
          }, _toDisplayString($setup.t("語言")) + ":" + _toDisplayString($setup.store.state.language), 1)) : _createCommentVNode("", true), $setup.user ? (_openBlock(), _createBlock(_component_router_link, {
            key: 5,
            onClick: _withModifiers($setup.logOut, ["prevent"]),
            class: "link",
            to: "#"
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString($setup.t("登出")), 1)];
            }),
            _: 1
          }, 8, ["onClick"])) : (_openBlock(), _createBlock(_component_router_link, {
            key: 6,
            onClick: _withModifiers($setup.logIn, ["prevent"]),
            class: "link",
            to: {
              name: 'Login'
            }
          }, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString($setup.t("登入")), 1)];
            }),
            _: 1
          }, 8, ["onClick"]))];
        }),
        _: 1
      }, 512), [[_vShow, $setup.mobile_nav]])];
    }),
    _: 1
  }), _withDirectives(_createElementVNode("div", {
    onClick: _cache[9] || (_cache[9] = _withModifiers(function ($event) {
      return $setup.mobile_nav = false;
    }, ["prevent"])),
    class: "mobile-nav-overlay"
  }, null, 512), [[_vShow, $setup.mobile_nav]])])]), $setup.show_language_modal ? (_openBlock(), _createBlock(_component_LanguageModal, {
    key: 0,
    onUpdateLanguage: $setup.updateLanguage,
    onCloseModal: $setup.closeModal
  }, null, 8, ["onUpdateLanguage", "onCloseModal"])) : _createCommentVNode("", true)], 64);
}