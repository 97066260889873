import menuIcon from "@/assets/icons/bars-regular.svg";
import userIcon from "@/assets/icons/user-alt-light.svg";
import adminIcon from "@/assets/icons/user-crown-light.svg";
import signOutIcon from "@/assets/icons/sign-out-alt-regular.svg";
import listIcon from "@/assets/icons/list1.svg";
import language from "@/assets/icons/language.svg";
import LanguageModal from '@/components/LanguageModal.vue';
import { useStore } from "vuex";
import { ref, reactive } from 'vue';
import { getAuth, signOut } from "firebase/auth";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
  name: 'UserMenu',
  components: {
    menuIcon: menuIcon,
    userIcon: userIcon,
    adminIcon: adminIcon,
    signOutIcon: signOutIcon,
    listIcon: listIcon,
    LanguageModal: LanguageModal,
    language: language
  },
  setup: function setup() {
    var _useI18n = useI18n(),
        t = _useI18n.t,
        locale = _useI18n.locale;

    var store = useStore();
    var auth = getAuth();
    var router = useRouter();
    var profile_menu = ref(false);
    var show_language_modal = ref(false);
    var user = reactive(JSON.parse(localStorage.getItem('user')));

    function closeMenu() {
      profile_menu.value = false;
    }

    function logOut() {
      store.dispatch("updateLoading", true);
      signOut(auth).then(function () {
        // Sign-out successful.
        console.log('Sign-out successful');
        store.dispatch('updateUser', undefined);
        store.dispatch("updateLoading", false); // router.push({ name: 'Home' })

        window.location.reload();
      }).catch(function (error) {
        // An error happened.
        console.log('error : ', error);
        store.dispatch("updateLoading", false);
      });
    }

    function closeModal() {
      show_language_modal.value = false;
    }

    function updateLanguage(lan) {
      store.dispatch("updateLanguage", lan);
      show_language_modal.value = false;

      if (lan === '繁體中文') {
        locale.value = 'zh-TW';
        localStorage.setItem('locale', 'zh-TW');
      } else if (lan === '简体中文') {
        locale.value = 'zh-CN';
        localStorage.setItem('locale', 'zh-CN');
      }
    }

    return {
      store: store,
      profile_menu: profile_menu,
      closeMenu: closeMenu,
      logOut: logOut,
      user: user,
      t: t,
      show_language_modal: show_language_modal,
      closeModal: closeModal,
      updateLanguage: updateLanguage
    };
  }
};