import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
  name: 'FreeCourseCard',
  props: ['course'],
  setup: function setup(props) {
    var _useI18n = useI18n(),
        t = _useI18n.t;

    var router = useRouter();

    function coursePage() {
      window.open(props.course.url, '_blank'); // router.push({ name: 'Course', params:{id: props.course.id} })
    }

    return {
      coursePage: coursePage,
      t: t
    };
  }
};