import AddAdmin from "@/components/AddAdmin.vue";
import OrderList from "@/components/OrderList.vue";
import CourseSell from "@/components/CourseSell.vue";
import AddCourse from "@/components/AddCourse.vue";
import AddLecture from "@/components/AddLecture.vue";
import AddUserOrder from "@/components/AddUserOrder.vue";
import GetEmail from "@/components/GetEmail.vue";
import { httpsCallable } from "firebase/functions";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, reactive } from 'vue';
import { useI18n } from "vue-i18n";
import { functions } from "@/firebase/firebaseInit.js";
import { getAuth } from "firebase/auth";
export default {
  name: 'Admin',
  components: {
    AddAdmin: AddAdmin,
    OrderList: OrderList,
    CourseSell: CourseSell,
    AddCourse: AddCourse,
    AddLecture: AddLecture,
    AddUserOrder: AddUserOrder,
    GetEmail: GetEmail
  },
  setup: function setup() {
    var _useI18n = useI18n(),
        t = _useI18n.t;

    var store = useStore();
    var menu = ref(1);
    return {
      t: t,
      menu: menu
    };
  }
};