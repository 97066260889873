import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default {
  name: 'CourseCard',
  props: ['course'],
  setup: function setup(props) {
    var _useI18n = useI18n(),
        t = _useI18n.t;

    var router = useRouter();
    var store = useStore();

    function coursePage() {
      var router_name = router.currentRoute.value.name;

      if (router_name != 'Course') {
        router.push({
          name: 'Course',
          params: {
            id: props.course.id
          }
        });
      } else {
        store.dispatch("updateLoading", true);
        var url = '/courses/' + props.course.id;
        window.location.replace(url);
      }
    }

    return {
      coursePage: coursePage,
      t: t
    };
  }
};