import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1bf6f94e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  key: 1,
  class: "initials"
};
var _hoisted_2 = {
  class: "profile-menu"
};
var _hoisted_3 = {
  class: "info"
};
var _hoisted_4 = {
  key: 1,
  class: "initials"
};
var _hoisted_5 = {
  class: "right"
};
var _hoisted_6 = {
  class: "options"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_avatar = _resolveComponent("el-avatar");

  var _component_userIcon = _resolveComponent("userIcon");

  var _component_router_link = _resolveComponent("router-link");

  var _component_listIcon = _resolveComponent("listIcon");

  var _component_adminIcon = _resolveComponent("adminIcon");

  var _component_language = _resolveComponent("language");

  var _component_signOutIcon = _resolveComponent("signOutIcon");

  var _component_LanguageModal = _resolveComponent("LanguageModal");

  var _directive_click_away = _resolveDirective("click-away");

  return _openBlock(), _createElementBlock(_Fragment, null, [$setup.store.state.user ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    onClick: _cache[2] || (_cache[2] = _withModifiers(function ($event) {
      return $setup.profile_menu = !$setup.profile_menu;
    }, ["stop"])),
    class: "profile"
  }, [$setup.store.state.user.photoURL ? (_openBlock(), _createBlock(_component_el_avatar, {
    key: 0,
    size: 40,
    src: $setup.store.state.user.photoURL
  }, null, 8, ["src"])) : $setup.store.state.user.displayName ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($setup.store.state.user.displayName[0]), 1)) : _createCommentVNode("", true), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [$setup.store.state.user.photoURL ? (_openBlock(), _createBlock(_component_el_avatar, {
    key: 0,
    size: 40,
    src: $setup.store.state.user.photoURL
  }, null, 8, ["src"])) : $setup.store.state.user.displayName ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($setup.store.state.user.displayName[0]), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [_createElementVNode("p", null, _toDisplayString($setup.store.state.user.displayName), 1), _createElementVNode("p", null, _toDisplayString($setup.store.state.user.email), 1)])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_router_link, {
    class: "option",
    to: {
      name: 'UserProfile',
      params: {
        id: $setup.store.state.user.uid
      }
    }
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_userIcon, {
        class: "icon"
      }), _createElementVNode("p", null, _toDisplayString($setup.t("個人檔案")), 1)];
    }),
    _: 1
  }, 8, ["to"]), _createVNode(_component_router_link, {
    class: "option",
    to: {
      name: 'PurchaseHistory'
    }
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_listIcon, {
        class: "icon"
      }), _createElementVNode("p", null, _toDisplayString($setup.t("購買紀錄")), 1)];
    }),
    _: 1
  }), $setup.store.state.user.admin ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    class: "option",
    to: {
      name: 'Admin'
    }
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_adminIcon, {
        class: "icon"
      }), _createElementVNode("p", null, _toDisplayString($setup.t("後台管理")), 1)];
    }),
    _: 1
  })) : _createCommentVNode("", true), $setup.store.state.language ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "option",
    onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
      $setup.show_language_modal = true;
    }, ["prevent"]))
  }, [_createVNode(_component_language, {
    class: "icon"
  }), _createElementVNode("p", null, _toDisplayString($setup.t("語言")) + ":" + _toDisplayString($setup.store.state.language), 1)])) : _createCommentVNode("", true), _createElementVNode("div", {
    onClick: _cache[1] || (_cache[1] = _withModifiers(function () {
      return $setup.logOut && $setup.logOut.apply($setup, arguments);
    }, ["prevent"])),
    class: "option"
  }, [_createVNode(_component_signOutIcon, {
    class: "icon"
  }), _createElementVNode("p", null, _toDisplayString($setup.t("登出")), 1)])])])), [[_directive_click_away, $setup.closeMenu], [_vShow, $setup.profile_menu]])])) : _createCommentVNode("", true), $setup.show_language_modal ? (_openBlock(), _createBlock(_component_LanguageModal, {
    key: 1,
    onUpdateLanguage: $setup.updateLanguage,
    onCloseModal: $setup.closeModal
  }, null, 8, ["onUpdateLanguage", "onCloseModal"])) : _createCommentVNode("", true)], 64);
}