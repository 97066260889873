import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_table_column = _resolveComponent("el-table-column");

  var _component_el_table = _resolveComponent("el-table");

  return _openBlock(), _createBlock(_component_el_table, {
    data: $setup.course_list,
    stripe: "",
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_table_column, {
        prop: "id",
        label: "課程id"
      }), _createVNode(_component_el_table_column, {
        prop: "title",
        label: "課程title"
      }), _createVNode(_component_el_table_column, {
        prop: "price",
        label: "價格"
      }), _createVNode(_component_el_table_column, {
        prop: "sell",
        label: "販賣數"
      }), _createVNode(_component_el_table_column, {
        prop: "total",
        label: "總販售金額"
      })];
    }),
    _: 1
  }, 8, ["data"]);
}