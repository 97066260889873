import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-575495fc"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "modal"
};
var _hoisted_2 = {
  key: 0,
  class: "modal-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_button = _resolveComponent("el-button");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [$setup.store.state.modal_message ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("p", null, _toDisplayString($setup.t($setup.store.state.modal_message)), 1), _createVNode(_component_el_button, {
    color: "#f59b83",
    class: "login-button",
    round: "",
    onClick: _withModifiers($setup.closeModal, ["prevent"])
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.t("關閉")), 1)];
    }),
    _: 1
  }, 8, ["onClick"])])) : _createCommentVNode("", true)]);
}