import { useStore } from "vuex";
import { ref, reactive } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "Modal",
  props: ['message'],
  setup: function setup() {
    var _useI18n = useI18n(),
        t = _useI18n.t;

    var store = useStore();

    function closeModal() {
      store.dispatch('updateShowModal', false);
    }

    return {
      store: store,
      closeModal: closeModal,
      t: t
    };
  }
};