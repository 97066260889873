import { createStore } from "vuex";

export default createStore({
  state:{
    login_page: false,
    user: undefined,
    loading: false,
    show_modal: false,
    modal_message: undefined,
    alert: false,
    buy_course: undefined,
    language: undefined
  },
  mutations:{
    LOGINPAGE(state, data) {
      state.login_page = data;
    },
    USER(state, data) {
      state.user = data;
    },
    LOADING(state, data) {
      state.loading = data;
    },
    SHOWMODAL(state, data){
      state.show_modal = data
    },
    MODALMESSAGE(state, data){
      state.modal_message = data
    },
    BUYCOURSE(state, data){
      state.buy_course = data
    },
    LANGUAGE(state, data){
      state.language = data
    },
    ALERT(state, data) {
      state.alert = data;
      setTimeout(() => {
        state.alert = false;
      }, 3000);
    },
  },
  actions:{
    updateLoginPage(context, data) {
      context.commit('LOGINPAGE', data);
    },
    updateUser(context, data) {
      context.commit('USER', data);
    },
    updateLoading(context, data) {
      context.commit('LOADING', data);
    },
    updateShowModal(context, data) {
      context.commit('SHOWMODAL', data);
    },
    updateModalMessage(context, data) {
      context.commit('MODALMESSAGE', data);
    },
    updateBuyCourse(context, data) {
      context.commit('BUYCOURSE', data);
    },
    updateLanguage(context, data) {
      context.commit('LANGUAGE', data);
    },
    updateAlert(context, data) {
      context.commit('ALERT', data);
    },
  },
  modules:{
    
  }
})
