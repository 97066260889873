import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClickAway from "vue3-click-away";
import { createI18n } from "vue-i18n";
import zh from "./language/zh-TW.json";
import cn from "./language/zh-CN.json";
import { getAuth } from "firebase/auth"
import { createMetaManager } from 'vue-meta'

let userLang = localStorage.getItem("locale")
if(!userLang){
  const auth = getAuth()
  auth.useDeviceLanguage()
  userLang = auth.languageCode;
  if(userLang==="zh-TW" || userLang==="zh-CN" || userLang==="zh-HK" || userLang==="zh-MO" || userLang==="zh-SG" || userLang==="zh-CHS" || userLang==="zh-CHT"){
    if(userLang==="zh-TW" || userLang==="zh-CHT" || userLang==="zh-HK"){
      localStorage.setItem("locale", "zh-TW")
    }
    else{
      localStorage.setItem("locale", "zh-CN")
    }
    
  }
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("locale") ?? "zh-TW",
  fallbackLocale: "zh-TW",
  messages: {
    "zh-TW": zh,
    "zh-CN": cn,
  }
});

createApp(App)
.use(store)
.use(router)
.use(VueClickAway)
.use(i18n)
.use(createMetaManager())
.mount('#app')
