import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "div-wrapper"
};
var _hoisted_2 = {
  class: "app"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_metainfo = _resolveComponent("metainfo");

  var _component_Header = _resolveComponent("Header");

  var _component_LoginHeader = _resolveComponent("LoginHeader");

  var _component_router_view = _resolveComponent("router-view");

  var _component_Footer = _resolveComponent("Footer");

  var _component_Loading = _resolveComponent("Loading");

  var _component_Modal = _resolveComponent("Modal");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_metainfo, null, {
    title: _withCtx(function (_ref) {
      var content = _ref.content;
      return [_createTextVNode(_toDisplayString(content ? "".concat(content, " | GrandmaCan-\u6211\u963F\u5B24\u90FD\u6703") : "GrandmaCan-\u6211\u963F\u5B24\u90FD\u6703"), 1)];
    }),
    _: 1
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [!$setup.store.state.login_page ? (_openBlock(), _createBlock(_component_Header, {
    key: 0
  })) : $setup.store.state.login_page ? (_openBlock(), _createBlock(_component_LoginHeader, {
    key: 1
  })) : _createCommentVNode("", true), _createVNode(_component_router_view), _withDirectives(_createVNode(_component_Footer, null, null, 512), [[_vShow, $setup.show_footer]]), _withDirectives(_createVNode(_component_Loading, null, null, 512), [[_vShow, $setup.store.state.loading]]), _withDirectives(_createVNode(_component_Modal, null, null, 512), [[_vShow, $setup.store.state.show_modal]])])])], 64);
}