import "core-js/modules/es.json.stringify.js";
import menuIcon from "@/assets/icons/bars-regular.svg";
import UserMenu from '@/components/UserMenu.vue';
import LanguageModal from '@/components/LanguageModal.vue';
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { ref, reactive } from 'vue';
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
  name: 'Header',
  components: {
    menuIcon: menuIcon,
    UserMenu: UserMenu,
    LanguageModal: LanguageModal
  },
  setup: function setup() {
    var _useI18n = useI18n(),
        t = _useI18n.t,
        locale = _useI18n.locale;

    var store = useStore();
    var auth = getAuth();
    var router = useRouter();
    var route = useRoute();
    var show_language_modal = ref(false);
    var user = undefined;

    if (localStorage.getItem('user')) {
      user = reactive(JSON.parse(localStorage.getItem('user')));
    }

    var moblie = ref(undefined);
    var mobile_nav = ref(undefined);
    var window_width = ref(undefined);

    function checkScreen() {
      window_width.value = window.innerWidth;

      if (window_width.value <= 750) {
        moblie.value = true;
        return;
      }

      moblie.value = false;
      mobile_nav.value = false;
      return;
    }

    function toggleMobileNav() {
      mobile_nav.value = !mobile_nav.value;
    }

    function logOut() {
      mobile_nav.value = false;
      store.dispatch("updateLoading", true);
      signOut(auth).then(function () {
        // Sign-out successful.
        console.log('Sign-out successful');
        store.dispatch('updateUser', undefined);
        store.dispatch("updateLoading", false); // router.push({ name: 'Home' })

        window.location.reload();
      }).catch(function (error) {
        // An error happened.
        console.log('error : ', error);
        store.dispatch("updateLoading", false);
      });
    }

    function logIn() {
      if (route.params.id) {
        var redirect_url = {
          name: 'Course',
          id: route.params.id
        };
        localStorage.setItem('redirect_url', JSON.stringify(redirect_url));
      } else {
        localStorage.removeItem('redirect_url');
      }
    }

    function closeModal() {
      show_language_modal.value = false;
    }

    function updateLanguage(lan) {
      store.dispatch("updateLanguage", lan);
      show_language_modal.value = false;

      if (lan === '繁體中文') {
        locale.value = 'zh-TW';
        localStorage.setItem('locale', 'zh-TW');
      } else if (lan === '简体中文') {
        locale.value = 'zh-CN';
        localStorage.setItem('locale', 'zh-CN');
      }
    }

    window.addEventListener("resize", checkScreen);
    checkScreen();
    return {
      user: user,
      store: store,
      moblie: moblie,
      mobile_nav: mobile_nav,
      window_width: window_width,
      toggleMobileNav: toggleMobileNav,
      logOut: logOut,
      t: t,
      show_language_modal: show_language_modal,
      closeModal: closeModal,
      updateLanguage: updateLanguage,
      logIn: logIn
    };
  }
};