import { useStore } from "vuex";
import { ref, reactive } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "LanguageModal",
  emit: ['closeModal', 'updateLanguage'],
  setup: function setup(props, context) {
    var _useI18n = useI18n(),
        t = _useI18n.t;

    var store = useStore();
    var language = ref(store.state.language);

    function closeButton() {
      context.emit('closeModal');
    }

    function updateLanguage() {
      context.emit('updateLanguage', language.value);
    }

    return {
      closeButton: closeButton,
      t: t,
      language: language,
      updateLanguage: updateLanguage
    };
  }
};