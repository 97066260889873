import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-07a7a7cb"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "form-wrap"
};
var _hoisted_2 = {
  class: "login"
};
var _hoisted_3 = {
  class: "login-register"
};
var _hoisted_4 = {
  class: "inputs"
};
var _hoisted_5 = {
  class: "input"
};
var _hoisted_6 = {
  class: "form-wrap"
};
var _hoisted_7 = {
  class: "login"
};
var _hoisted_8 = {
  class: "login-register"
};
var _hoisted_9 = {
  class: "inputs"
};
var _hoisted_10 = {
  class: "input"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_button = _resolveComponent("el-button");

  return _openBlock(), _createElementBlock("main", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("form", _hoisted_2, [_createElementVNode("h2", null, _toDisplayString($setup.t("課程sample")), 1), _createElementVNode("p", _hoisted_3, _toDisplayString($setup.t("請輸入課程id")), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_withDirectives(_createElementVNode("input", {
    type: "text",
    placeholder: "課程id",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.sample_id = $event;
    })
  }, null, 512), [[_vModelText, $setup.sample_id]])]), _withDirectives(_createElementVNode("div", {
    class: "error"
  }, _toDisplayString($setup.t($setup.err_message)), 513), [[_vShow, $setup.err]])]), _createVNode(_component_el_button, {
    onClick: _withModifiers($setup.getSample, ["prevent"]),
    color: "#f59b83",
    size: "large",
    class: "login-button",
    round: ""
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.t("發送")), 1)];
    }),
    _: 1
  }, 8, ["onClick"])])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("form", _hoisted_7, [_createElementVNode("h2", null, _toDisplayString($setup.t("新增課程")), 1), _createElementVNode("p", _hoisted_8, _toDisplayString($setup.t("請輸入課程資訊")), 1), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_withDirectives(_createElementVNode("textarea", {
    rows: "20",
    cols: "100",
    type: "text",
    placeholder: "課程資訊",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $setup.course_info = $event;
    })
  }, null, 512), [[_vModelText, $setup.course_info]])]), _withDirectives(_createElementVNode("div", {
    class: "error"
  }, _toDisplayString($setup.t($setup.err_message)), 513), [[_vShow, $setup.err]])]), _createVNode(_component_el_button, {
    onClick: _withModifiers($setup.send, ["prevent"]),
    color: "#f59b83",
    size: "large",
    class: "login-button",
    round: ""
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.t("發送")), 1)];
    }),
    _: 1
  }, 8, ["onClick"])])])]);
}