import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5aacc139"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "course-cards container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_CourseCard = _resolveComponent("CourseCard");

  return _openBlock(), _createElementBlock("main", null, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.courses, function (course) {
    return _openBlock(), _createBlock(_component_CourseCard, {
      course: course,
      key: course.id
    }, null, 8, ["course"]);
  }), 128))])]);
}