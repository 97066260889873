// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // 測試帳號
  // apiKey: "AIzaSyC9RKRzqNPEyYyq3RQEj3fuEgdzYbAijBs",
  // // authDomain: "gctest-aed06.firebaseapp.com",
  // authDomain: "298c-36-230-231-145.ngrok-free.app",
  // projectId: "gctest-aed06",
  // storageBucket: "gctest-aed06.appspot.com",
  // messagingSenderId: "821463999206",
  // appId: "1:821463999206:web:9528f6cdbbd45bc1325127",
  // measurementId: "G-E2NSJF5CJZ"

  // 正式帳號
  apiKey: "AIzaSyDUM7T9brbb3wciHFzTQFOpzajLFUxJnZE",
  // authDomain: "grandmacan-2dae4.firebaseapp.com",
  authDomain: "grandmacan.com",
  // authDomain: "298c-36-230-231-145.ngrok-free.app",
  projectId: "grandmacan-2dae4",
  storageBucket: "grandmacan-2dae4.appspot.com",
  messagingSenderId: "816908324187",
  appId: "1:816908324187:web:54c15513e2a1d84c205e7e",
  measurementId: "G-9D8WGD0X8C"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const storage = getStorage(firebaseApp);
export const functions = getFunctions(firebaseApp, 'asia-east1');
export const db = getFirestore();