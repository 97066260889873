import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-df2222d2"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "course-cards container"
};
var _hoisted_2 = {
  key: 0,
  class: "container no-course"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_CourseCard = _resolveComponent("CourseCard");

  return _openBlock(), _createElementBlock("main", null, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.enrolled_course, function (course) {
    return _openBlock(), _createBlock(_component_CourseCard, {
      onClick: _withModifiers(function ($event) {
        return $setup.learnPage(course.id);
      }, ["prevent"]),
      course: course,
      key: course.id
    }, null, 8, ["onClick", "course"]);
  }), 128))]), !$setup.enrolled_course ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("h2", null, _toDisplayString($setup.t("尚無課程")), 1)])) : _createCommentVNode("", true)]);
}