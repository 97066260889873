import { useI18n } from "vue-i18n";
import { useMeta } from 'vue-meta';
export default {
  name: "PrivacyPolicy",
  components: {},
  setup: function setup() {
    var _useI18n = useI18n(),
        t = _useI18n.t;

    useMeta({
      title: t("使用者條款")
    });
    return {
      t: t
    };
  }
};