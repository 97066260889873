import menuIcon from "@/assets/icons/bars-regular.svg";
import { ref, reactive } from 'vue';
export default {
  name: 'Header',
  components: {
    menuIcon: menuIcon
  },
  setup: function setup() {
    var moblie = ref(null);
    var mobile_nav = ref(null);
    var window_width = ref(null);

    function checkScreen() {
      window_width.value = window.innerWidth;

      if (window_width.value <= 750) {
        moblie.value = true;
        return;
      }

      moblie.value = false;
      mobile_nav.value = false;
      return;
    }

    window.addEventListener("resize", checkScreen);
    checkScreen();
    return {
      moblie: moblie,
      mobile_nav: mobile_nav,
      window_width: window_width
    };
  }
};