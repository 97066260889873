import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-824aadda"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "form-wrap"
};
var _hoisted_2 = {
  class: "login"
};
var _hoisted_3 = {
  class: "login-register"
};
var _hoisted_4 = {
  class: "inputs"
};
var _hoisted_5 = {
  class: "input"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_user = _resolveComponent("user");

  var _component_el_button = _resolveComponent("el-button");

  return _openBlock(), _createElementBlock("main", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("form", _hoisted_2, [_createElementVNode("h2", null, _toDisplayString($setup.t("設定管理員")), 1), _createElementVNode("p", _hoisted_3, _toDisplayString($setup.t("請輸入使用者UID")), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_withDirectives(_createElementVNode("input", {
    type: "text",
    placeholder: "uid",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.uid = $event;
    })
  }, null, 512), [[_vModelText, $setup.uid]]), _createVNode(_component_user, {
    class: "icon"
  })]), _withDirectives(_createElementVNode("div", {
    class: "error"
  }, _toDisplayString($setup.t($setup.err_message)), 513), [[_vShow, $setup.err]])]), _createVNode(_component_el_button, {
    onClick: _withModifiers($setup.send, ["prevent"]),
    color: "#f59b83",
    size: "large",
    class: "login-button",
    round: ""
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.t("發送")), 1)];
    }),
    _: 1
  }, 8, ["onClick"])])])]);
}