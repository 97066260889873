import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-40435186"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src"];
var _hoisted_2 = {
  class: "info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "course-card",
    onClick: _cache[0] || (_cache[0] = function () {
      return $setup.coursePage && $setup.coursePage.apply($setup, arguments);
    })
  }, [_createElementVNode("img", {
    src: $props.course.thumbnail
  }, null, 8, _hoisted_1), _createElementVNode("div", _hoisted_2, [_createElementVNode("h4", null, _toDisplayString($setup.t($props.course.title)), 1), _createElementVNode("h6", null, _toDisplayString($setup.t($props.course.description)), 1)])]);
}