import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-37ed65c7"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "title container"
};
var _hoisted_2 = {
  class: "course-cards container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_FreeCourseCard = _resolveComponent("FreeCourseCard");

  return _openBlock(), _createElementBlock("main", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("h1", null, _toDisplayString($setup.t("免費學習")), 1)]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.courses, function (course) {
    return _openBlock(), _createBlock(_component_FreeCourseCard, {
      course: course,
      key: course.id
    }, null, 8, ["course"]);
  }), 128))])]);
}