import { useI18n } from "vue-i18n";
export default {
  name: "LoginModal",
  emit: ['closeLogin', 'editEmail'],
  setup: function setup(props, context) {
    var _useI18n = useI18n(),
        t = _useI18n.t;

    function closeButton() {
      context.emit('closeLogin');
    }

    function editMail() {
      context.emit('closeLogin');
      context.emit('editEmail');
    }

    return {
      closeButton: closeButton,
      editMail: editMail,
      t: t
    };
  }
};