import envelope from "../assets/icons/envelope-regular.svg";
import { useI18n } from "vue-i18n";
export default {
  name: "Footer",
  components: {
    envelope: envelope
  },
  setup: function setup() {
    var _useI18n = useI18n(),
        t = _useI18n.t;

    return {
      t: t
    };
  }
};