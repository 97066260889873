import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-35c09297"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "countdown"
};
var _hoisted_2 = {
  class: "block"
};
var _hoisted_3 = {
  class: "digit"
};
var _hoisted_4 = {
  class: "block"
};
var _hoisted_5 = {
  class: "digit"
};
var _hoisted_6 = {
  class: "block"
};
var _hoisted_7 = {
  class: "digit"
};
var _hoisted_8 = {
  class: "block"
};
var _hoisted_9 = {
  class: "digit"
};
var _hoisted_10 = {
  class: "block"
};
var _hoisted_11 = {
  class: "digit"
};
var _hoisted_12 = {
  class: "block"
};
var _hoisted_13 = {
  class: "digit"
};
var _hoisted_14 = {
  class: "block"
};
var _hoisted_15 = {
  class: "digit"
};
var _hoisted_16 = {
  class: "block"
};
var _hoisted_17 = {
  class: "digit"
};
var _hoisted_18 = {
  class: "block"
};
var _hoisted_19 = {
  class: "digit"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_clock = _resolveComponent("clock");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, [_createVNode(_component_clock, {
    class: "clock"
  }), _createTextVNode(_toDisplayString($setup.t("特價倒數")), 1)])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString($setup.day), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("p", _hoisted_7, _toDisplayString($setup.t("天")), 1)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("p", _hoisted_9, _toDisplayString($setup.hour), 1)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("p", _hoisted_11, _toDisplayString($setup.t("小時")), 1)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("p", _hoisted_13, _toDisplayString($setup.minute), 1)]), _createElementVNode("div", _hoisted_14, [_createElementVNode("p", _hoisted_15, _toDisplayString($setup.t("分")), 1)]), _createElementVNode("div", _hoisted_16, [_createElementVNode("p", _hoisted_17, _toDisplayString($setup.second), 1)]), _createElementVNode("div", _hoisted_18, [_createElementVNode("p", _hoisted_19, _toDisplayString($setup.t("秒")), 1)])]);
}