import "core-js/modules/es.math.trunc.js";
import clock from "@/assets/icons/clock.svg";
import { ref, computed } from 'vue';
import { useI18n } from "vue-i18n";
export default {
  name: 'AddAdmin',
  components: {
    clock: clock
  },
  props: ['date'],
  setup: function setup(props) {
    var _useI18n = useI18n(),
        t = _useI18n.t;

    var now = ref(0);
    var date = Math.trunc(new Date(props.date).getTime() / 1000 - 28800);
    now.value = Math.trunc(new Date().getTime() / 1000);
    console.log(date);
    console.log(now.value);
    window.setInterval(function () {
      now.value = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
    var day = computed(function () {
      var d = Math.trunc((date - now.value) / 60 / 60 / 24);

      if (d < 0) {
        return 0;
      } else {
        return d;
      }
    });
    var hour = computed(function () {
      var h = Math.trunc((date - now.value) / 60 / 60) % 24;

      if (h < 0) {
        return 0;
      } else {
        return h;
      }
    });
    var minute = computed(function () {
      var m = Math.trunc((date - now.value) / 60) % 60;

      if (m < 0) {
        return 0;
      } else {
        return m;
      }
    });
    var second = computed(function () {
      var s = (date - now.value) % 60;

      if (s < 0) {
        return 0;
      } else {
        return s;
      }
    });
    return {
      t: t,
      day: day,
      hour: hour,
      minute: minute,
      second: second
    };
  }
};