import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-ccdb67a6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "modal"
};
var _hoisted_2 = {
  class: "modal-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_button = _resolveComponent("el-button");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h2", null, _toDisplayString($setup.t("請先驗證您的帳戶")), 1), _createVNode(_component_el_button, {
    round: "",
    onClick: _withModifiers($setup.closeButton, ["prevent"]),
    plain: ""
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.t("關閉")), 1)];
    }),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_el_button, {
    color: "#f59b83",
    round: "",
    onClick: _withModifiers($setup.editMail, ["prevent"]),
    style: {
      "color": "white"
    }
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString($setup.t("登入")), 1)];
    }),
    _: 1
  }, 8, ["onClick"])])]);
}